export const DEFAULT_FONT = 'Texta';

export const INSTALLATION_ONGOING_COLOR = '#165A7B';
export const NOT_CONNECTED_STATUS_COLOR = '#7C828B';
export const CONNECTED_COLOR = '#00975F';

export const OK_COLOR = '#00975F';
export const WARNING_COLOR = '#FF9E22';
export const ERROR_COLOR = '#FF5F59';
export const NOT_CONNECTED_MONITORING_COLOR = '#7C828B';

export const PIE_CHART_COLORS = {
  countColor: '#FFFFFF',
  itemHiddenStyle: '#c6c8d2',
  itemHoverStyle: '#c6c8d2',
  itemStyleColor: 'hsla(0,0%,100%,.5)',
  titleColor: '#c6c8d2',
  bgColor: '#0A1627'
};

export const CIRCLE_CHART_COLORS = {
  itemHiddenStyle: '#c6c8d2',
  itemHoverStyle: '#c6c8d2',
  itemStyleColor: 'hsla(0,0%,100%,.5)',
  titleColor: '#c6c8d2',
  bgColor: '#0A1627',
  OVERALL_ACTIVE_COLOR: '#D5D900',
  ONE_YEAR_ACTIVE_COLOR: '#FF5F59',
  ONE_MONTH_ACTIVE_COLOR: '#00975F',
  OVERALL_EMPTY_COLOR: '#D5D9001a',
  ONE_YEAR_EMPTY_COLOR: '#FF5F591a',
  ONE_MONTH_EMPTY_COLOR: '#00975F1a'
};

export const tooltip = {
  backgroundColor: '#0A1627',
  style: {
    color: '#fff',
    fontSize: '16px'
  }
};
