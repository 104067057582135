import React from 'react';
import { useDispatch } from 'react-redux';
import Switch from 'react-switch';
import { ACCOUNT_REQUEST_DEBUG_LOG_URL } from '../../../api/apiUrls';

import { dataListUpdateRow } from '../../../containers/DataList/slice';
import { installedSMSendRequest } from '../../../containers/InstalledSM/slice';

const RenderSwitcher = (row) => {
  const { index, original, cb, sizes } = row;
  const { debug_logging, _id } = original;
  const dispatch = useDispatch();

  const toggleSwitcher = (rowID, enabled, gatewayID) => {
    dispatch(installedSMSendRequest({
      method: 'post',
      url: `${ACCOUNT_REQUEST_DEBUG_LOG_URL}/${gatewayID}`,
      config: { data: { debug_logging: enabled } },
      onResult: { errorMessage: 'debugLoggingFailed' }
    }));

    if (cb) {
      dispatch(cb());
    } else {
      dispatch(dataListUpdateRow({ listID: 'installed_sm', rowID, updates: { debug_logging: enabled } }));
    }
  };

  const toggle = () => toggleSwitcher(index, !debug_logging, _id);

  return (
    <span>
      <Switch
        checked={debug_logging || false}
        onChange={toggle}
        width={sizes?.width || 50}
        height={sizes?.height || 25}
        onColor="#d5d900"
        className="switcher"
      />
    </span>
  );
};

export default RenderSwitcher;
