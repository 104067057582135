import view from './view.json';

const replace = {
  ASKOMATolltip: view.ezeeAskomaTooltip,
  changeSMID: view.ezeeChangeSMID,
  reqSMID: view.ezeeReqSMID,
  SMID: view.ezeeSMID,
  SMIDTolltip: view.ezeeSMIDTolltip,
  enableSmid: view.ezeeEnableSmid
};

export default replace;
