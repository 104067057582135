import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../ModalWindow';
import { Preloader } from '../../../components';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { getInstalledSMIsLoadingSelector } from '../../../redux-store/selectors/installedSM';
import { getModalsRemoveFirmwareUpdateSelector } from '../../../redux-store/selectors/modals';
import { REMOVE_FIRMWARE_UPDATE_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

import i18n from '../../../i18n';

import '../index.css';

/**
 * Renders delete gateway confirmation modal window for InstalledSM page
 * @memberof module:InstalledSM
 */
const DeleteFirmwareWindow = (props) => {
  const { onSubmit } = props;
  const dispatch = useDispatch();

  const isLoading = useSelector(getInstalledSMIsLoadingSelector);
  const modal = useSelector(getModalsRemoveFirmwareUpdateSelector);

  const { data } = modal || {};

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: REMOVE_FIRMWARE_UPDATE_MODAL_ID }));

  return (
    <ModalWindow modalID={REMOVE_FIRMWARE_UPDATE_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('delFirmware')}</h5>
      </div>
      {isLoading ? (
        <div className="installedsm-delete-prealoder-container">
          <Preloader />
        </div>
      ) : (
        <div>
          <div className="modal-body">
            <div className="text-confirmation">{i18n.t('confirmDelFirmware')}</div>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton onClickHandler={onSubmit.bind(null, { id: data?.id || data?._id })} customButtonClass="error-btn" type="delete" />
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

DeleteFirmwareWindow.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default DeleteFirmwareWindow;
