import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../ModalWindow';
import { Preloader } from '../../../components';
import { CancelButton, PrimaryButton } from '../../../components/UIKit';
import { getModalsIsLoadingSelector, getModalsRemoveSubUserDataSelector } from '../../../redux-store/selectors/modals';
import { REMOVE_SUB_USER_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

import i18n from '../../../i18n';

const RemoveSubUser = (props) => {
  const { onSubmit } = props;
  const dispatch = useDispatch();

  const isLoading = useSelector(getModalsIsLoadingSelector);
  const data = useSelector(getModalsRemoveSubUserDataSelector);

  const onSubmitHandler = () => onSubmit({ id: data.id || data._id, currentSubMeterId: data.currentSubMeterId });

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: REMOVE_SUB_USER_MODAL_ID }));

  return (
    <ModalWindow modalID={REMOVE_SUB_USER_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('remSubUser')}</h5>
      </div>
      {isLoading ? (
        <div className="delete-device-preloader-container">
          <Preloader />
        </div>
      ) : (
        <>
          <div className="modal-body">
            <div className="text-confirmation">{i18n.t('remModalBodySubUser')}</div>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton onClickHandler={onSubmitHandler} i18nLabel="removeBtn" />
          </div>
        </>
      )}
    </ModalWindow>
  );
};

RemoveSubUser.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default RemoveSubUser;
