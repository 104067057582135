import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { DataListPagination, DataListFilterV2 } from '../DataList';
import { DeleteSubUserWindow } from './modals';
import { Footer, SearchField, TopBar, Pagination, Breadcrumbs } from '../../components';
import { visibleWebSelector } from './selectors';
import { modalWindowSend, REMOVE_DATA_MODAL_ID } from '../ModalWindow/slice';
import { dataListReloadData } from '../DataList/slice';
import { SUB_USER_URL } from '../../api/apiUrls';
import columns from './columns';
import i18n from '../../i18n';
import { dataListID } from './constants';
import { transformResponse } from './utils';

import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';
import { ConfirmDeleteUserWindow } from '../../modals';
import { ReactTableV2 } from '../../components/ReactTableV2/ReactTableV2';

/**
 * Renders page for list of sub users
 * @memberof module:SubUsersTable
 */
const SubUsersTable = (props) => {
  const { myRoleType } = props;
  const dispatch = useDispatch();

  const { [dataListID]: pageSettings } = useSelector(getPagesSettingsSelector);
  const visibleWeb = useSelector(visibleWebSelector);

  const columnsSubUsers = useMemo(() => columns(myRoleType, pageSettings), [myRoleType, pageSettings]);
  const defaultParams = pageSettings.queries;

  const sendRemoveUser = ({ id }) => dispatch(modalWindowSend({
    modalID: REMOVE_DATA_MODAL_ID,
    requestConfig: {
      method: 'delete',
      url: `/user/${id}`
    },
    cb: () => dataListReloadData({ listID: dataListID })
  }));

  return (
    <div className="main-table-container">
      <DeleteSubUserWindow onSubmit={sendRemoveUser} />
      <ConfirmDeleteUserWindow userRole={i18n.t('subUser').toLowerCase()} />
      <TopBar
        updateButton
        showNavigation
        visibleWeb={visibleWeb}
      />
      <div className="container-fluid">
        <Breadcrumbs crumbs={[{ name: i18n.t('breadcrumbsSubSMUs') }]} />

        <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-20">
          <div className="col-xl-12">
            <div className="row align-items-top">
              <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 m--margin-bottom-10">
                <DataListFilterV2
                  listURL={SUB_USER_URL}
                  Component={SearchField}
                  componentProps={{
                    defaultValue: pageSettings.queries.search,
                    searchByFields: 'sm_id,email,first_name,last_name,company,street,city,zip,plant',
                    placeholder: i18n.t('searchAllTablesElements')
                  }}
                  transformResponse={transformResponse}
                  listID={dataListID}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
          <ReactTableV2
            columns={columnsSubUsers}
            listID={dataListID}
            listURL={SUB_USER_URL}
            defaultParams={defaultParams}
            transformResponse={transformResponse}
          />
          <DataListPagination
            Component={Pagination}
            listID={dataListID}
            defaultPageSize={10}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

SubUsersTable.propTypes = {
  myRoleType: PropTypes.string.isRequired
};

export default SubUsersTable;
