import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../../ModalWindow';
import { DELETE_INVITED_USER_MODAL_ID } from '../../constants';
import { closeModalWindow } from '../../../ModalWindow/slice';
import { PrimaryButton, CancelButton } from '../../../../components/UIKit';
import { getModalsDeleteInvitedUserSelector } from '../../../../redux-store/selectors/modals';
import { addNotification } from '../../../NotificationGenerator/slice';

import i18n from '../../../../i18n';
import { useRemoveInvitedUserMutation } from '../../services';

const DeleteInvitedUserModal = (props) => {
  const { userId = null } = props;
  const dispatch = useDispatch();

  const [removeInvitedUser, {
    error: removeInvitedUserError
  }] = useRemoveInvitedUserMutation();

  const modal = useSelector(getModalsDeleteInvitedUserSelector);
  const handleOnClose = () => dispatch(closeModalWindow({ modalID: DELETE_INVITED_USER_MODAL_ID }));
  const removeInvitedUserHandler = () => {
    removeInvitedUser({ endUserId: userId, invitedUserId: modal.data.id || modal.data._id });
    handleOnClose();
  };

  useEffect(() => {
    if (removeInvitedUserError) {
      dispatch(addNotification({ type: 'error', text: i18n.t(removeInvitedUserError.message) }));
    }
  }, [dispatch, removeInvitedUserError]);

  return (
    <ModalWindow modalID={DELETE_INVITED_USER_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">
          {i18n.t('deleteInvitedUserTitle')}
        </h5>
      </div>
      <div className="modal-body">
        <p className="message">
          {i18n.t('deleteInvitedUserSubtitle')}
        </p>
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleOnClose} />
        <PrimaryButton onClickHandler={removeInvitedUserHandler} type="delete" customButtonClass="error-btn" />
      </div>
    </ModalWindow>
  );
};

DeleteInvitedUserModal.propTypes = {
  userId: PropTypes.string
};

export default DeleteInvitedUserModal;
