import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector, reduxForm, change } from 'redux-form';
import { connect, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import ModalWindow from '../../containers/ModalWindow';
import { CustomDatePicker, Preloader } from '../../components';
import { CustomDateInput } from '../../components/ReduxFormFields';
import { CancelButton, PrimaryButton } from '../../components/UIKit';

import {
  required,
  validateDate,
  validateSameOrBeforeDate,
  validateSameOfAfterDate,
  validateDateAfterToday
} from '../../containers/validation';
import { getModalsDataExportModalSelector, getModalsIsLoadingSelector } from '../../redux-store/selectors/modals';
import { dataPickerValidator, disableDate, formatDate, normalizeFromDate, normalizeToDate } from './utils';
import { DATEPICKER_MASK, FROM_DATE, TO_DATE } from './constant';
import { DATA_EXPORT_MODAL_ID, closeModalWindow } from '../../containers/ModalWindow/slice';

import i18n from '../../i18n';

import './styles.scss';

const DataExportModalWindow = (props) => {
  const { handleSubmit, dispatch, reset, modalID = DATA_EXPORT_MODAL_ID, modalTitle = 'dataExportModalTitle' } = props;
  const dataExportFormSelector = formValueSelector(modalID);

  const fromDate = useSelector((state) => dataExportFormSelector(state, FROM_DATE));
  const toDate = useSelector((state) => dataExportFormSelector(state, TO_DATE));
  const modal = useSelector(getModalsDataExportModalSelector);
  const isLoading = useSelector(getModalsIsLoadingSelector);

  /**
   * Sets field value for [modalID]
   * @param {string} field Redux form field name
   * @param {number} value Redux form field value
   */

  const changeFormFieldValue = (field, value) => dispatch(change(modalID, field, value));

  /**
   * If date is valid then get start of the day for the date
   * and change form fromDate field value
   * @param {number} value From date value
   */
  const handleDatePickerFromDateChange = (value) => {
    const nextValue = moment(value).startOf('day');
    if (nextValue.isValid()) {
      changeFormFieldValue(FROM_DATE, nextValue.valueOf());
    }
  };

  /**
   * If date is valid and if 'To' date is equal to 'today'
   * then set 'To' equal to current moment date
   * @param {number} value "To date"
   */
  const handleDatePickerToDateChange = (value) => {
    const today = moment();
    const nextValue = moment(value);

    if (nextValue.isValid()) {
      if (nextValue.isSame(today, 'day')) {
        changeFormFieldValue(TO_DATE, today.valueOf());
      } else {
        changeFormFieldValue(TO_DATE, nextValue.endOf('day').subtract(1, 'ms').valueOf());
      }
    }
  };

  /**
   * Validates if fromDate value is the same or before toDate value
   * @param {number} value fromDate timestamp value
   * @returns {undefined|string}
   */
  const fromDateBeforeToDateValidator = useCallback((value) => validateSameOrBeforeDate(value, toDate), [toDate]);

  /**
   * Validates if toDate value is the same of after fromDate value
   * @param {number} value toDate timestamp value
   * @returns {undefined|string}
   */
  const toDateAfterFromValidator = useCallback((value) => validateSameOfAfterDate(fromDate, value), [fromDate]);

  const handleOnCloseHandler = () => dispatch(closeModalWindow({ modalID }));

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">
          <i className="la la-download download-icon mr-2 text-main" />
          <span>{i18n.t(modalTitle)}</span>
        </h5>
      </div>
      {isLoading ? (
        <div className="employee-add-prealoder-container">
          <Preloader />
        </div>
      ) : (
        <div>
          <div className="modal-body">
            <form
              id={modalID}
              onSubmit={handleSubmit}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              <div className="d-flex justify-content-center">
                <div className="d-flex flex-column datepicker-col">
                  <div className="d-flex justify-content-center align-items-top datepicker-input-field-container">
                    <span className="date-input-label">
                      {i18n.t('fromDateLabel')}
                    </span>
                    <div className="form-group m-form__group datepicker-input-field">
                      <Field
                        name={FROM_DATE}
                        component={CustomDateInput}
                        className="m-input"
                        autoComplete="off"
                        validate={[required, validateDate, fromDateBeforeToDateValidator, validateDateAfterToday]}
                        preventAuto
                        placeholder={DATEPICKER_MASK}
                        format={formatDate}
                        normalize={normalizeFromDate}
                      />
                    </div>
                  </div>
                  <CustomDatePicker
                    value={fromDate}
                    maxDetail="date"
                    handleChange={handleDatePickerFromDateChange}
                    className="export-data-datepicker-container"
                    validateDate={dataPickerValidator}
                    disabledDate={disableDate}
                  />
                </div>
                <div className="d-flex flex-column datepicker-col">
                  <div className="d-flex justify-content-center align-items-top datepicker-input-field-container">
                    <span className="date-input-label">
                      {i18n.t('toDateLabel')}
                    </span>
                    <div className="form-group m-form__group datepicker-input-field">
                      <Field
                        name={TO_DATE}
                        component={CustomDateInput}
                        className="m-input"
                        autoComplete="off"
                        validate={[required, validateDate, toDateAfterFromValidator, validateDateAfterToday]}
                        preventAuto
                        placeholder={DATEPICKER_MASK}
                        format={formatDate}
                        normalize={normalizeToDate}
                      />
                    </div>
                  </div>
                  <CustomDatePicker
                    value={toDate}
                    maxDetail="date"
                    handleChange={handleDatePickerToDateChange}
                    className="export-data-datepicker-container"
                    validateDate={dataPickerValidator}
                    disabledDate={disableDate}
                  />
                </div>
              </div>
            </form>
            <div className="d-flex justify-content-center export-all-client-data-info">
              <span>{i18n.t('exportAllClientsDataInfo')}</span>
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-center">
            <CancelButton onClickHandler={handleOnCloseHandler} />
            <PrimaryButton form={modalID} isSubmit i18nLabel="confirmBtn" type="save" />
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

DataExportModalWindow.propTypes = {
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  modalID: PropTypes.string,
  modalTitle: PropTypes.string
};

const form = reduxForm()(DataExportModalWindow);

export default connect(
  (_, { initialValues, modalID }) => ({ initialValues, form: modalID })
)(form);
